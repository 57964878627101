import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request, RequestConfig } from './utils'
import { API_PATH } from '~/settings'

const NAMESPACE = 'auth'
const BASE_URL = `${import.meta.env.VITE_RAILS_URL}${API_PATH}`

export type Credentials = {
  login: string
  password: string
}

export type AccessTokenData = {
  userId?: number
  organizationId?: number
  personId?: number
  accountId?: number
  issued: boolean
}


type SessionData = {
  isLoggedIn: boolean
  personId: number
  accountId: number
  userId?: number | null
  organizationId?: number | null
}

type SessionCheckResponse = {
  ok: boolean,
  data: SessionData | null
}

const sessionCheckUrl = new URL(`${BASE_URL}/session/check`)
export const useSessionCheckQuery = () => {
  return useQuery({
    queryKey: ['sessioncheck'],
    queryFn: () => {
      return request<null, SessionCheckResponse>(sessionCheckUrl)
    },
    select: (response: SessionCheckResponse) => {
      const { ok, data } = response
      return { isloggedIn: ok, ...data }
    },
    retry: 1,
  })
}





// SIGNUP

export const signupRequest = async (credentials: Credentials): Promise<User> => {
  const url = new URL(`${BASE_URL}/${NAMESPACE}/signup`)
  const options: RequestConfig<Credentials> = { body: credentials }
  return await request<Credentials, User>(url, 'POST', options)
}

export const useSignupRequest = (options?: UseMutationOptions<User, Error, Credentials>) =>
  useMutation<User, Error, Credentials>(signupRequest, options)

// LOGIN

type UserId = Pick<User, 'id'>

const loginRequestUrl = new URL(`${BASE_URL}/${NAMESPACE}/login`)
export const loginRequest = async (credentials: Credentials): Promise<UserId> => {
  const options: RequestConfig<Credentials> = { body: credentials }
  return await request<Credentials, UserId>(loginRequestUrl, 'POST', options)
}

export const useLoginRequest = (options?: UseMutationOptions<UserId, Error, Credentials>) =>
  useMutation<UserId, Error, Credentials>(loginRequest, options)

// LOGOUT

export const logoutRequest = async (): Promise<void> => {
  const url = new URL(`${BASE_URL}/${NAMESPACE}/logout`)
  return await request<null, void>(url, 'POST')
}

export const useLogoutRequest = (options?: UseMutationOptions<void, Error>) =>
  useMutation<void, Error>(() => logoutRequest(), options)

// ACCESS TOKEN

export const accessTokenRequest = async (): Promise<AccessTokenData> => {
  const url = new URL(`${BASE_URL}/${NAMESPACE}/access-token`)
  const response = await request<null, AccessTokenData>(url, 'GET')
  return response
}

export const useAccessTokenRequest = (options?: UseQueryOptions<AccessTokenData, Error>) =>
  useQuery<AccessTokenData, Error>(['useAccessTokenRequest'], () => accessTokenRequest(), {
    enabled: false,
    ...options
  })
