import { useMemo, useEffect } from 'react'
import { AuthContext, AuthError } from '~/auth'
import { useSessionCheckQuery } from '~/api/auth'
import { honeybadger } from '~/settings'

type AuthProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

export const AuthProvider = (props: AuthProviderProps) => {
  const { data, isLoading, isSuccess } = useSessionCheckQuery()
  const { personId, accountId, userId, organizationId } = {
    personId: null, accountId: null, userId: null, organizationId: null,
    ...data
  }

  // const loggedInUser = useLoggedInUserQuery()

  // const [isLoading, setIsLoading] = useState(true)

  // const [personId, setPersonId] = useState<number>(null)
  // const [accountId, setAccountId] = useState<number>(null)
  // const [userId, setUserId] = useState<number>(null)
  // const [organizationId, setOrganizationId] = useState<number>(null)


  useEffect(() => {
    if (!isSuccess) return
    // if (loggedInUser.isLoading) return

    // setPersonId(personId)
    // setAccountId(accountId)
    // setUserId(userId)
    // setOrganizationId(organizationId)

    honeybadger.setContext({
      person_id: personId || 'X',
      account_id: accountId || 'X',
      user_id: userId || 'X',
      organization_id: organizationId || 'X',
    })

    // setIsLoading(isSessionLoading || loggedInUser.isLoading)
  }, [
    isSuccess,
    userId,
    organizationId,
    personId,
    accountId,
    // setUserId,
    // setOrganizationId,
    // setPersonId,
    // setAccountId,
    // setIsLoading
  ])

  const authContextData = useMemo(
    () => ({
      userId: userId,
      organizationId: organizationId,
      personId: personId,
      accountId: accountId,
      isLoggedIn: !!personId && !!accountId,
      isLoading: isLoading,
    }),
    [
      userId,
      organizationId,
      personId,
      accountId,
      isLoading,
    ]
  )

  return <AuthContext.Provider value={authContextData}>{props.children}</AuthContext.Provider>
}
