import { createContext } from 'react'

export type AuthContextData = {
  userId: number
  organizationId: number
  personId: number
  accountId: number
  isLoggedIn: boolean
  isLoading: boolean
}

export const AuthContext = createContext<AuthContextData>({
  userId: null,
  organizationId: null,
  personId: null,
  accountId: null,
  isLoggedIn: false,
  isLoading: true,
})
